<script setup>
import {useForm, usePage} from '@inertiajs/vue3';
import {AuthenticationCardLogo} from '@/Components/Brand';
import {Checkbox, InputError, InputLabel, PrimaryButton, TextInput} from '@/Components/Form';
import {AnchorLink} from '@/Components/Frontend/Navigation';
import AuthLayout from '@/Layouts/AuthLayout.vue';
import {AuthenticationCard} from '@/Layouts/Partials';
import GoogleButton from '@/Pages/Auth/Partials/GoogleButton.vue';

defineProps({
    canResetPassword: {
        type: Boolean,
        default: false,
    },
    canRegister: {
        type: Boolean,
        default: usePage()?.props?.canRegister,
    },
    canSso: {
        type: Boolean,
        default: usePage()?.props?.feature_flags?.sso ?? false,
    },
    status: {
        type: String,
        default: null,
    },
});

const form = useForm({
    email: '',
    password: '',
    remember: false,
});

const submit = (loginRoute) => {
    form.transform((data) => ({
        ...data,
        remember: form.remember ? 'on' : '',
    })).post(loginRoute, {
        onFinish: () => form.reset('password'),
    });
};
</script>

<template>
    <AuthLayout :title="$t('Log in')">
        <AuthenticationCard>
            <template #logo>
                <AuthenticationCardLogo />
            </template>

            <div v-if="status" class="mb-4 font-medium text-sm text-green-600">
                {{ status }}
            </div>

            <form @submit.prevent="submit(route('login'))">
                <div>
                    <InputLabel name="email" :value="$t('Email')" />
                    <TextInput
                        v-model="form.email"
                        name="email"
                        type="email"
                        class="mt-1 block w-full"
                        required
                        autofocus
                        autocomplete="username" />
                    <InputError class="mt-2" :message="form.errors.email" />
                </div>

                <div class="mt-4">
                    <InputLabel name="password" :value="$t('Password')" />
                    <TextInput
                        v-model="form.password"
                        name="password"
                        type="password"
                        class="mt-1 block w-full"
                        required
                        autocomplete="current-password" />
                    <InputError class="mt-2" :message="form.errors.password" />
                </div>

                <div class="block mt-4">
                    <label class="flex items-center">
                        <Checkbox v-model:checked="form.remember" name="remember" />
                        <span class="ms-2 text-sm">{{ $t('Remember me') }}</span>
                    </label>
                </div>

                <div class="flex items-center justify-end mt-4">
                    <AnchorLink
                        v-if="canResetPassword"
                        :inertia="true"
                        :href="route('password.request')"
                        :label="$t('Forgot your password?')">
                        {{ $t('Forgot your password?') }}
                    </AnchorLink>

                    <PrimaryButton class="ms-4" :class="{'opacity-25': form.processing}" :disabled="form.processing">
                        {{ $t('Log in') }}
                    </PrimaryButton>
                </div>
            </form>
            <div class="mt-4">
                <AnchorLink v-if="canRegister" :inertia="true" :href="route('register')" :label="$t('Register')">
                    {{ $t("I don't have an account") }}
                </AnchorLink>
            </div>
            <div v-if="canSso" class="mt-10">
                <GoogleButton :label="$t('auth.login_with_social', {provider: 'Google'})" />
            </div>
        </AuthenticationCard>
    </AuthLayout>
</template>
